
.about-navbar {
    z-index: 2;    
}
.about-navbar-icon {
    height: 30px;
    border-radius: 1;
    margin-right: 15px;
}

.about-main-container {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    z-index: 0;
}

.about-hero {
    height: 100vh;
    z-index: -1;
}

.about-text-container {
    z-index: 2;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 12.5vh;
    width: 85%vw;
    max-width: 500px;
}
.about-text {
    text-align: left;
    color: #fff;
    font-size: 14px;
    line-height: 2;
    margin: 0px 20px 0px 20px;
}

.about-footer-container {
    z-index: 2;
    position: absolute;
    bottom: 1vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.about-footer-text {
    align-self: center;
    color: #fff;
    font-size: 20px;
    text-decoration: none;
}
.about-footer-icon {
    z-index: 5;
    height: 40px;
    color: #fff;
    align-self: center;
}