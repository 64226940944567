@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300&display=swap');

.projects-navbar {
    z-index: 2;    
}
.projects-navbar-icon {
    height: 30px;
    border-radius: 1;
    margin-right: 15px;
}

.projects-main-container {
    background-color: black;
}

.projects-main-container {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    z-index: 0;
}

.projects-hero {
    height: 100vh;
    z-index: -1;
}

.projects-projects-container {
    z-index: 2;
    position: absolute;
    top: 15vh;
    bottom: 20vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0px 30px 0px 30px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.projects-project-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 280px;
    height: 480px;
    margin: 20px 7.5px 0px 7.5px;
}

.projects-name {
    font-family: 'Oswald', sans-serif;
    font-weight: 200;
    font-size: 28px;
    margin: 0px 0px 5px 10px;
    color: #c9fdd7;
}
.projects-project-img-container {
    display: flex;
    justify-content: center;
}
.projects-description {
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    color: #f8fcfb;
    margin: 10px 0px 0px 0px;
}
.projects-icons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.projects-icons {
    color: #79d1c3;
    height: 35px;
    width: auto;
    margin: 10px 5px 0px 5px;
}
.projects-button-group {
    display: flex;
    justify-content: center;

}
.projects-button {
    background-color: #6892d5;
    margin: 20px 5px 0px 5px;
}